<template>
  <div>
    <b-row class="search-row">
      <b-col sm="4" class="search-col-1">
        <b-button @click="handleCartClick" variant="primary" class="btnCart mr-2">
          {{ this.gideonNavigationPageInfo.buttonCart }} ({{ this.cartTotalProducts }})
        </b-button>
        <b-button @click="handleOrdersClick" variant="primary" class="mr-2">
          {{ this.gideonNavigationPageInfo.buttonMyOrder }}
        </b-button>
        <b-button @click="handleFavoritesClick" variant="primary" class="">
          {{ this.gideonNavigationPageInfo.buttonFavorites }}
        </b-button>
      </b-col>
      <b-col sm="4" class="search-col-2 mt-3">
        <template v-if="showMerchTotal">
          {{ this.gideonNavigationPageInfo.labelMerchTotal }}<br />
          {{ this.cartTotalPrice }}
        </template>
      </b-col>
      <b-col sm="4" class="search-col-3 mb-3 mt-3 align-right">
        <b-form-input
          title="Search"
          id="search"
          :placeholder="gideonNavigationPageInfo.buttonSearch"
          v-model="searchText"
          required
          @keyup.enter.native="handleSearchClick"
        ></b-form-input>
        <b-button variant="primary" size="sm" class="btn btn-primary" @click="handleSearchClick()">{{
          this.gideonNavigationPageInfo.buttonSearch
        }}</b-button>
      </b-col>
    </b-row>
    <b-row class="search-row" v-if="showDescriptionText">
      <b-col sm="12" class="search-col-1">
        <div class="text-1" v-html="this.gideonNavigationPageInfo.information" />
      </b-col>
    </b-row>
    <b-row class="search-row mt-5" v-if="backLabel !== ''">
      <b-col sm="12" class="search-col-1">
        <b-button @click="handleBackClick" variant="primary" class="">
          &lt; {{ this.gideonNavigationPageInfo.buttonBack }}
        </b-button>
        <span class="back-label ml-5">{{ this.backLabel }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'store-topnav-search',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'store-topnav-search': {
            tcSearchRequiresAtLeastOneTerm: 'Search requires at least one term.',
            tcOk: 'Ok'
          }
        }
      }
    },
    showDescriptionText: {
      type: Boolean,
      default: true,
    },
    showMerchTotal: {
      type: Boolean,
      default: true,
    },
    backLabel: {
      type: String,
      default: '',
    },
    backRouteName: {
      type: String,
      default: 'store-home',
    },
  },
  data() {
    return {
      storeNavPagePayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Navigation',
      },
      searchText: '',
    }
  },
  methods: {
    ...mapActions({
      setMRPSearchText: 'mrp/setMRPSearchText',
      setStoreSearchText: 'gideonStore/setStoreSearchText',
    }),
    handleSearchClick() {
      if (this.searchText.trim() === '') {
        this.$swal({
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: '#d33',
          confirmButtonText: this.i18n['store-topnav-search'].tcOk,
          text: this.i18n['store-topnav-search'].tcSearchRequiresAtLeastOneTerm,
        }).then((result) => {
          return false
        })
      } else {
        this.setStoreSearchText(this.searchText)
        if (this.$route.name !== 'store-search') {
          this.$router.push({ name: 'store-search' })
        }
      }
    },
    handleFavoritesClick() {
      if (this.$route.name !== 'store-favorites') {
        this.$router.push({ name: 'store-favorites' })
      }
    },
    handleOrdersClick() {
      if (this.$route.name !== 'store-orders') {
        this.$router.push({ name: 'store-orders' })
      }
    },
    handleCartClick() {
      if (this.$route.name !== 'store-cart') {
        this.$router.push({ name: 'store-cart' })
      }
    },
    handleBackClick() {
      this.$router.push({ name: this.backRouteName })
    },
    async page_load() {},
  },
  computed: {
    ...mapGetters({
      gideonStoreSearchText: 'gideonStore/gideonStoreSearchText',
      gideonNavigationPageInfo: 'gideonStore/gideonNavigationPageInfo',
      gideonStoreCart: 'gideonStore/gideonStoreCart',
    }),
    cartTotalProducts() {
      return this.gideonStoreCart[0].Cart_Total_Products
    },
    cartTotalPrice() {
      return this.gideonStoreCart[1].Total_Price
    },
  },
  async created() {
    if (this.$route.name === 'store-search') {
      this.searchText = this.gideonStoreSearchText
    }
    this.page_load()
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.back-label {
  font-weight: bold;
  text-transform: uppercase;
}
.text-1 {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.text-2 {
  text-align: center;
  color: $secondary-color;
  width: 70%;
  margin: 0 auto;
}

.search-row {
  @include breakpoint(mobile) {
    display: block;
    margin: auto 0;
  }
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  .search-col-1 {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    .btn {
      padding: 8px;
    }
  }
  .search-col-2 {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }
  .search-col-3 {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    .btn {
      padding: 8px;
    }
  }
}

.btnCart {
  background-image: url(~@/assets/svgs/Cart_Icon.svg);
  padding-right: 30px !important;
  background-position: 90%;
  background-repeat: no-repeat;
}
.align-right {
  text-align: right;
}

.btn-primary {
  @include breakpoint(mobile) {
    font-size: 11px;
  }
}
</style>
